import Vue from 'vue'
import router from './router'

import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle, domTitle } from '@/util/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['Login','Check','Reg','FreeAticle'] // no redirect whitelist
//无默认首页的情况
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  // to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`))
  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    next()
    NProgress.done()
  } else {
    if (whiteList.includes(to.name)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/Login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
