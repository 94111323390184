/*
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-29 17:19:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\main.js
 */
import Vue from 'vue'
import VueStorage from 'vue-ls'
import router from './router';
import store from './store';
import App from './App.vue'
// import '@/styles/global.css'
import 'lib-flexible'
import echarts from 'echarts'
import scroll from 'vue-seamless-scroll'
import svg from 'svg-progress-bar'
// import '@/styles/font.css'
import api from './api/install.js'
import _ from 'lodash';
import moment from 'moment'
import './permission' // permission control
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

Vue.use(api)

import { ConfigProvider, PageHeader, List, Card, Select, Pagination, Collapse, Drawer, Menu, Modal, Table, Form, Row, Col, Input, Button, Icon, Tabs, message, DatePicker, Popover, Rate,notification,Anchor,Progress,Spin,Radio,Upload} from 'ant-design-vue';
import vueConfig from '../vue.config';
Vue.use(ConfigProvider)
Vue.use(PageHeader)
Vue.use(List)
Vue.use(Card)
Vue.use(Select)
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(Drawer)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use(Rate)
Vue.use(VueStorage)
Vue.use(Anchor)
Vue.use(Progress)
Vue.use(Spin)
Vue.use(Radio)
Vue.use(Upload)

Vue.prototype.$message = message;

Vue.prototype.$confirm = Modal.confirm

Vue.prototype.$echarts = echarts

Vue.prototype._ = _;

Vue.prototype.$moment = moment;

Vue.config.productionTip = false

Vue.use(scroll)

Vue.use(svg,{componentName: 'svg-bar'})

// var _hmt = _hmt || [];
// window._hmt = _hmt;
// (function() {
//   var hm = document.createElement("script");
//   hm.src = "https://hm.baidu.com/hm.js?b93a46ec8b067305a2e30d99f216dd03";
//   var s = document.getElementsByTagName("script")[0]; 
//   s.parentNode.insertBefore(hm, s);
// })();



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning