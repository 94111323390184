/*
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-26 14:47:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\api\axios.js
 */
import Vue from 'vue'
import axios from 'axios';
import { message, Modal, notification } from 'ant-design-vue' ///es/notification
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: '/api', // api base_url
  timeout: 600000 // 请求超时时间
});

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)

    if (error.response.status === 403) {
      console.log('服务器403啦，要重新登录！')
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 500) {
      if (data.message.length > 0) {
        message.error(data.message)
      }
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// 设置 post、put 默认 Content-Type
service.defaults.headers.post['Content-Type'] = 'application/json';
service.defaults.headers.put['Content-Type'] = 'application/json';

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    const a = config;
    if (a.method === 'post' || a.method === 'put') {
      // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
      a.data = JSON.stringify(a.data);
    }
    // 请求发送前进行处理
    return a;
  },
  // 请求错误处理
  err
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.request.responseType === 'blob') {
      return response
    }
    const code = response.data.code
    if (code === 1011006 || code === 1011007 || code === 1011008 || code === 1011009 || code === 1011004) {
      Modal.error({
        title: '提示：',
        content: response.data.message,
        okText: '重新登录',
        onOk: () => {
          Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()
        }
      })
    } else if (code === 1013002 || code === 1016002 || code === 1015002 || code === 1011002) {
      message.error(response.data.message)
      return response.data
    } else {
      return response.data
    }
  },
  err
);

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}, err);

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
  return service;
}
