<template>
  <div>
    <a-modal width="500px" :visible="visible" :footer="null" class="main_box" @cancel="handleCancel" :maskClosable="false">
      <a-spin tip="加载中" :spinning="loading">
      <div class="pay_title">洛阳明泰健康咨询有限公司</div>
      <div class="pay_desc">中医艾灸一站式咨询网站</div>
      <div v-if="paySuccess">
        <div class="qr_code half_height"><span class="pay_success"><a-icon type="check"/>支付成功</span><span class="pay_money">¥{{payMoney}}</span></div>
      </div>
      <div v-else>
        <router-link class="buy_vip" to="/My?index=1" v-if="payType==4">购买会员省钱更划算</router-link>
        <div class="qr_code" id="qrCode" v-if="payWay!=2" ref="qrCodeUrl"></div>
        <div class="qr_code half_height" v-else ref="qrCodeUrl"><span class="money">¥{{money}}</span><span class="pay_money">-{{payMoney}}</span></div>
        <div class="pay_way" v-if="payWay!=2">{{payWay==0?'微信支付':'支付宝支付'}}<span class="pay_money">¥{{payMoney}}</span></div>
        <a-button class="pay_way white_color" size="large" type="primary" v-else :disabled="payType!=4" @click="walletPay">余额支付</a-button>
        <div class="pay_way_desc" v-if="payType==4">请支付后观看内容</div>
        <div class="pay_other">
          <div class="pay_way1" v-if="payWay!=0" @click="changePayWay(0)"><img src="../../static/images/mingtai/wxpay.png"/>微信支付</div>
          <div class="pay_way1" v-else @click="changePayWay(1)"><img src="../../static/images/mingtai/zfbpay.png"/>支付宝支付</div>
          <div class="pay_way2" :class="payType!=4?'pay_disabled':''" v-if="payWay!=2" @click="changePayWay(2)"><span class="money">¥{{money}}</span>余额支付</div>
          <div class="pay_way2" v-else @click="changePayWay(1)"><img src="../../static/images/mingtai/zfbpay.png"/>支付宝支付</div>
        </div>
      </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
  name:'pay',
  data() {
    return {
      visible: false,
      loading: false,
      loopTime: 2000,//轮询间隔时长
      payMoney: 0,//支付金额，单位为分
      money: 0,//用户余额
      payWay: 0,//0-微信支付，1-支付宝支付，2-余额支付
      payType: 4,//2-钱包余额消费；3-钱包余额充值；4-单次消费；5-购买会员卡
      payObj: null,//支付对象
      callback: null,//回调函数
      paySuccess: false,//支付状态
      outTradeNo: null,//交易流水号
      timeObj: null,//定时任务
    };
  },
  beforeDestroy(){
    if(this.timeObj){
      clearTimeout(this.timeObj)
    }
  },
  created() {
    
  },
  methods: {
    changePayWay(payWay){
      if(this.payType!=4 && payWay==2){
        return
      }
      this.$refs.qrCodeUrl.innerHTML = ""
      this.payWay = payWay
      this.getPayData()
    },
    showPay(payObj,callback){
      if(payObj.payType==3 && (!payObj.payMoney || payObj.payMoney==0)){
        return
      }else{
        this.payMoney = payObj.payMoney/100
      }
      if(payObj.payWay){
        this.payWay = payObj.payWay
      }
      if(payObj.payType){
        this.payType = payObj.payType
      }
      this.callback = callback
      this.payObj = payObj
      this.$api.getInfo().then(res => {
        this.money = res.data.money/100
        this.getPayData()
        this.visible = true
      })
    },
    //余额支付
    async walletPay(){
      this.loading = true
      let data = {type:2,showId:this.payObj.bizId}
      let res = await this.$api.walletPay(null,data)
      if(res.success){
          this.afterPaySuccess()
      }
      this.loading = false
    },
    //请求支付数据，呈现二维码
    async getPayData(){
      if(this.payWay==2){
        return
      }
      this.loading = true
      let data = {amount:this.payMoney*100,type:this.payType,bizId:this.payObj.bizId,payment:this.payWay==0?'wxpay':(this.payWay==1?'alipay':'walletpay')}
      let res = await this.$api.toPay(null,data)
      let codeUrl = this.payWay==0?res.data.wxPayVo.code_url:res.data.aliPayVo.orderInfo
      this.outTradeNo = res.data.payOrder.outTradeNo
      if(res.success){
        this.$refs.qrCodeUrl.innerHTML = ""
        new QRCode(this.$refs.qrCodeUrl, {
            text: codeUrl, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
      }
      this.longPoll()
      this.loading = false
    },
    //长轮询查询支付状态
    async longPoll(){
      let data = {type:this.payType,id:this.outTradeNo}
      let res = await this.$api.orderStatus(null,data)
      if(res.success){
        if(res.data.isPay){
          this.afterPaySuccess()
        }else{
          if(this.timeObj){
            clearTimeout(this.timeObj)
          }
          this.timeObj = setTimeout(() => {
            this.longPoll()
          },this.loopTime)
        }
      }
    },
    //支付成功的操作
    afterPaySuccess(){
      this.paySuccess = true
      this.visible = true
      setTimeout(() => {
        this.visible = false
        if(this.callback){
          this.callback()
          this.handleCancel()
        }
      },1000)
    },
    handleCancel(){
      if(this.timeObj){
        clearTimeout(this.timeObj)
      }
      this.visible = false
      this.loading = false
      this.payWay = 0
      this.payType = 0
      
      this.payMoney = 0
      this.money = 0
      this.payObj = null
      this.callback = null
      this.paySuccess = false
      this.outTradeNo = null
      this.timeObj = null
    }
  },
};
</script>

<style lang="scss" scoped>
  .main_box{
    text-align: center;
    .pay_title{
      font-size: 18px;
      font-weight: bolder;
      color: #063a61;
    }
    .pay_desc{
      font-size: 14px;
      color: #b6b6b6;
      margin-bottom: 20px;
    }
    .buy_vip{
      font-size: 17px;
      font-weight: lighter;
      color: #063a61;
      margin-bottom: 10px;
    }
    .white_color{
      font-size: 20px !important;
      background-color: #063a61;
      color: white !important;
    }
    .white_color:hover{
      background-color: #075087;
    }
    .pay_way{
      margin-top: 20px;
      font-size: 25px;
      font-weight: bolder;
      color: black;
    }
    .pay_way_desc{
      color: #7b7b7b;
      font-size: 15px;
    }
    .pay_other{
      display: flex;
      width: 80%;
      margin: 0 auto;
      margin-top: 8%;
      img{
        width: 30px;
        margin-right: 5px;
      }
    }
    .pay_way1{
      width: 50%;
      color: #063a61;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: left;
      cursor: pointer;
    }
    .pay_way2{
      width: 50%;
      color: #063a61;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: right;
      cursor: pointer;
      .money{
        font-size:30px;
        font-weight: bolder;
        margin-right: 5px;
      }
    }
    .pay_disabled{
      color: #a1a1a1;
      cursor: not-allowed;
    }
    .qr_code{
      width: 200px;
      height: 200px;
      margin: 0 auto;
      margin-top: 10px;
      .money{
        color: #063a61;
        font-size:80px;
        font-weight: bolder;
        margin-right: 20px;
      }
      .pay_money{
        color: #063a61;
        font-size:30px;
      }
    }
    .pay_success{
      color: green;
      font-size: 30px;
    }
    .half_height{
      width: 100%;
      height: 100px;
    }
  }
</style>