/*
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-26 13:37:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\vue.config.js
 */

const path = require("path");

module.exports = {
  // publicPath: './', 
  lintOnSave: 'error',
  configureWebpack: {
    externals: {
      'AMap': 'AMap'
    },
    module: {
      rules: [
        {
          test:/\.(woff2?|eot|ttf|otf)(\?.*)$/,
          loader:'url-loader'
        },
        {
          test: /\.(ico)$/,
          loader:'file-loader'
        }
      ]
    },
    resolve: {
      alias: {
        "@": path.resolve(__dirname,"./src")
      }
    }
  },
  css: {
    loaderOptions: {
      less: {
        lessOptions: {
          javascriptEnabled: true,//允许链式调用的换行
        }
      },
      css: {},
      postcss: {
        plugins: [
          require('postcss-px2rem')({
            remUnit: 192              //设计图宽度/10
          })
        ]
      }
    }
  },
  devServer: {
    proxy: {
      "/ug": {
        target: "http://c.m.163.com",             //网易全国疫情接口
        // changeOrigin: true,
      },
      "/newpneumonia": {
        target: "https://voice.baidu.com",        //百度河南疫情接口
        // changeOrigin: true,
      },
      '/api': {
        // target: 'http://221.176.159.218:83/',   //正式接口
        // target: 'http://127.0.0.1:81/',         //正式接口
        // target: "http://kfapi.0379yl.com/",    //测试接口
        target: 'http://127.0.0.1:82',     //开发接口
        pathRewrite: {
          '^/api' : ''
        }
      },
    },
  }
  
}