import api from './index.js'

const header = {};

//字典查询
const dropDown = function(params,data) {
  return api.get('/sysDictType/dropDownNoLogin', params, header, data)
}
//系统配置
const configDetail = function(params,data) {
  return api.get('/sysConfig/page', params, header, data)
}
const articleList = function(params,data) {
  return api.post('/articleTab/getPageList', params, header,data)
}
const articleInfo = function(params,data) {
  return api.post('/articleTab/getInfo', params, header,data)
}
const consumPageList = function(params,data) {
  return api.post('/consumptionLog/getPageList', params, header,data)
}
const feedbackPageList = function(params,data) {
  return api.post('/app/tAppFeedback/getAppTAppFeedbackPageList', params, header,data)
}
const feedbackAdd = function(params,data) {
  return api.post('/tFeedback/add', params, header,data)
}
const feedbackDelete = function(params,data) {
  return api.post('/tFeedback/delete', params, header,data)
}
const getLevelInfo = function(params,data) {
  return api.post('/sysUser/getLevelInfo', params, header,data)
}
const toPay = function(params,data) {
  return api.post('/Pay', params, header,data)
}
const orderStatus = function(params,data) {
  return api.post('/consumptionLog/getIsPay', params, header,data)
}
const walletPay = function(params,data) {
  return api.post('/consumptionLog/add', params, header,data)
}
const sendSmsCode = function(params,data) {
  return api.post('/sms/sendLoginMessage', params, header,data)
}
const getFreeArticlePageList = function(params,data) {
  return api.post('/freeapi/getFreeArticlePageList', params, header,data)
}
const getFreeArticleInfo = function(params,data) {
  return api.post('/freeapi/getFreeArticleInfo', params, header,data)
}

export default {
  dropDown,
  configDetail,
  articleList,
  articleInfo,
  consumPageList,
  feedbackPageList,
  feedbackAdd,
  feedbackDelete,
  getLevelInfo,
  toPay,
  orderStatus,
  walletPay,
  sendSmsCode,
  getFreeArticlePageList,
  getFreeArticleInfo,
};
