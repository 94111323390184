/*
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-26 14:53:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\api\install.js
 */
import apiList from './apiList';
import apiList1 from './apiList1';

const install = function (Vue) {
  if (install.installed) {
    return;
  }
  install.installed = true;
  Object.defineProperties(Vue.prototype, {
    $api: {
      get() {
        return {...apiList,...apiList1};
      },
    },
  });
};

export default {
  install,
};
