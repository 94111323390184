<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-27 09:58:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="Footercontainer">
    Copyright  2017-2022 www.lysyrk.com All rights reserved 
    洛阳明泰健康咨询有限公司 版权所有  <a href="https://beian.miit.gov.cn/">豫ICP备17013877号-2</a>
  </div>
</template>

<script>
export default {
  name: "sFooter",
  data() {
    return {};
  },
  created() {},
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.Footercontainer {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #03233B;
  overflow: hidden;
  position: relative;
  padding: 0px 120px;
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: lighter;
}
</style>