import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../view/index/index.vue';

Vue.use(VueRouter);

const routes = [
  { // 首页
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: false
    },
  },
  { // 404 先回到首页
    path: '*',
    redirect: '/Login',
    meta: {
      keepAlive: false
    },
  },
  { // 审核页面
    path: '/Check',
    name: 'Check',
    component: () => import(/* webpackChunkName: "Check" */ '../view/index/check'),
    meta: {
      keepAlive: false
    },
  },
  { // 文章
    path: '/Aticle',
    name: 'Aticle',
    component: () => import(/* webpackChunkName: "oldAnalyse" */ '../view/index/aticle'),
    meta: {
      keepAlive: false
    },
  },
  { // 免费文章
    path: '/FreeAticle',
    name: 'FreeAticle',
    component: () => import(/* webpackChunkName: "oldAnalyse" */ '../view/index/aticleFree'),
    meta: {
      keepAlive: false
    },
  },
  { // 个人中心
    path: '/My',
    name: 'My',
    component: () => import(/* webpackChunkName: "My" */ '../view/my/index'),
    meta: {
      keepAlive: true
    },
  },
  { // 登录
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../view/login/login'),
    meta: {
      keepAlive: true
    },
  },
  { // 注册
    path: '/Reg',
    name: 'Reg',
    component: () => import(/* webpackChunkName: "Reg" */ '../view/login/reg'),
    meta: {
      keepAlive: false
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router;
