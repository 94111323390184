import Vue from 'vue'
import { login,logout,updatePwd,forgetPwd } from '@/api/apiList'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/util/util'

const user = {
    state: {
      token: '',
      name: '',
      welcome: '',
      info: {},
    },

    mutations: {
      SET_TOKEN: (state, token) => {
        state.token = token
      },
      SET_NAME: (state, { name, welcome }) => {
        state.name = name
        state.welcome = welcome
      },
      SET_INFO: (state, info) => {
        state.info = info
    },
},

actions: {
  
  // 登录
  Login ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login({},userInfo).then(response => {
        if(!response.success){
          reject(response)
          return
        }
        const result = response.data
        Vue.ls.set(ACCESS_TOKEN, result, 7 * 24 * 60 * 60 * 1000)
        commit('SET_TOKEN', result)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 登出
  Logout ({ commit, state }) {
    return new Promise((resolve) => {
      logout(state.token).then(() => {
        resolve()
      }).catch(() => {
        resolve()
      }).finally(() => {
        commit('SET_TOKEN', '')
        Vue.ls.remove(ACCESS_TOKEN)
      })
    })
  },

  // 修改密码
  UpdatePwd({ commit },passwords) {
    return new Promise((resolve,reject) => {
      updatePwd(passwords).then((res) => {
        resolve(res)
        return
      }).catch(() => {
      resolve()
    })
  })
  },

  // 修改密码
  ForgetPwd({ commit },passwords) {
    return new Promise((resolve,reject) => {
      forgetPwd(passwords).then((res) => {
        resolve(res)
        return
      }).catch(() => {
      resolve()
    })
  })
  }

  // 获取用户信息
  // GetInfo ({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     getLoginUser().then(response => {
  //       if(response.success){
  //     const data = response.data
  //     commit('SET_INFO', data)
  //     commit('SET_NAME', { name: data.name, welcome: welcome() })

  //     resolve(data)
  //   }else{
  //     reject(new Error(data.message))
  //   }
  // }).catch(error => {
  //     reject(error)
  //   })
  // })
  // },

  

}
}

export default user
