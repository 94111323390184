import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules/index.js'
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    modules
  },
  state: {
  },
  mutations: {

  },
  actions: {

  },
})
