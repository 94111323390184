/*
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-28 10:21:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\api\apiList.js
 */
import api from './index.js'
import { Base64 } from '@/util/util'
var base64 = new Base64();

const header = {};

export function login(params,data) {
  let newData = {}
  newData.account = base64.encode(data.account);
  newData.password = base64.encode(data.password);
  return api.post("/login", params, header,newData)
}

export function logout(params,data) {
  return api.post("/logout", params, header,data)
}

const register = function(params,data) {
  let newData = {...data}
  newData.password = base64.encode(data.password);
  return api.post("/register", params, header,newData)
}
const registerInfo = function(params,data) {
  let newData = {}
  newData.account = base64.encode(data.account);
  newData.password = base64.encode(data.password);
  return api.post("/registerInfo", params, header,newData)
}

export function updatePwd(params,data) {
  let newData = {}
  newData.password = base64.encode(data.password);
  newData.newPassword = base64.encode(data.newPassword);
  newData.confirm = base64.encode(data.confirm);
  return api.post("/sysUser/updatePwd", params, header,newData)
}

export function forgetPwd(params,data) {
  let newData = {...data}
  newData.password = base64.encode(data.password);
  return api.post("/forgetPwd", params, header,newData)
}


const getInfo = function(params,data) {
  return api.post("/sysUser/getInfo", params, header,data)
}

export default {
  register,
  registerInfo,
  getInfo,
  updatePwd,
  forgetPwd,
};
