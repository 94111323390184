<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-27 15:15:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="container">
    <s-header />
    <div class="main">
      <div class="serchBox">
        <img
          class="serchIcon"
          src="../../static/images/mingtai/serchIcon.png"
        />
        <input
          class="serchInp"
          v-model="serchObj.search"
          placeholder="输入您要搜索的内容"
        />
        <button class="serchBtn" @click="getData">搜索</button>
      </div>
      <div class="typeBox">
        <div class="tagBox">
          <div
            v-for="(item, index) in flags"
            :key="index"
            :class="{ active: index == flaged }"
            @click="flagTab(index)"
          >
            {{ item.value }}
          </div>
        </div>
        <div class="tagBox">
          <div
            v-for="(item, index) in time"
            :key="index"
            :class="{ active: index == timeed }"
            @click="timeTab(index)"
          >
            {{ item.value }}
          </div>
        </div>
        <br/>
        <div class="tagBox">
          <div
            v-for="(item, index) in type"
            :key="index"
            :class="{ active: index == typeed }"
            @click="typeTab(index)"
          >
            {{ item.value }}
          </div>
        </div>
      </div>

      <div class="tipBox" v-if="auditObj">
        {{auditObj.message}}
      </div>

      <div class="listbox" v-else>
        <span v-if="data.length<=0" class="no-data">嗷哦，暂无数据~</span>
        <a-row v-else :gutter="[16,16]" style="width:105%">
          <a-col :span="4" v-for="(item, index) in data" :key="index" >
            <a-card hoverable style="width: 200px;height: 200px;" :bodyStyle="{padding:'8px'}" @click="toContent(item)">
              <img
                slot="cover"
                alt="example"
                :src="item.coverUrl"
              />
              <div v-if="item.videoUrl" class="tcount">{{ timeFormat(item.time) }}</div>
              <div class="card-title" v-html="handleTitle(item.title)"></div>
              <div class="czBox">
                  <div class="countTxt">{{ item.viewsNum }}</div>
                  <div class="dateTxt">{{ item.createTime.substring(0,10) }}</div>
                </div>
            </a-card>
          </a-col>
        </a-row>

        <div class="footetPage">
          <a @click="start" :disabled="current==1">首页</a>
          <a @click="prev" :disabled="current<2">上一页</a>
          <a @click="next" :disabled="current>=totalPage">下一页</a>
          <a @click="end" :disabled="current>=totalPage">尾页</a>
          <span>共{{ total }}条</span>
        </div>
      </div>
      
    </div>
    <pay ref="pay"/>
    <s-footer />
  </div>
</template>

<script>
import sHeader from "@/view/components/Header";
import sFooter from "@/view/components/Footer";
import pay from "@/view/components/pay";

export default {
  components: {
    sHeader,
    sFooter,
    pay
  },
  data() {
    return {
      auditObj: null,
      // ,'最多点赞','最多收藏'
      flags: [{id:null,value:"综合排序"}, {id:1,value:"最多点击"}, {id:0,value:"最新发布"}],
      flaged: 0,
      time: [{id:null,value:"全部时长"}, {id:"0-10",value:"10分钟以下"}, {id:"10-30",value:"10-30分钟"}, {id:"30-60",value:"30-60分钟"}, {id:"60",value:"60分钟以上"}],
      timeed: 0,
      type: [],
      typeed: 0,
      data: [],
      serchObj: {
        search: null,
        flag: null,
        startTime: null,
        endTime: null,
        type: null,
        click: null,
      },
      current: 1, //当前页
      pageSize: 18, //一页多少条
      total: 0, //总条数
      totalPage: 1, //总共页数
      item: null,//需要跳转的文章对象
    };
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    //支付成功回调函数
    callback(){
      if(this.item){
        this.toContent(this.item)
      }
    },
    //标题处理，若有搜索关键词就加红处理
    handleTitle(title){
      if(this.serchObj.search){
        let newTitle = null
        let keyArr = this.serchObj.search.trim().split(/\s+/)
        keyArr.forEach(key => {
          let oldTitle = newTitle?newTitle:title
          newTitle = oldTitle.replace(new RegExp("("+key+")","g"),"<font color='#f25d8e'>$1</font>")
        });
        return newTitle
      }else{
        return title
      }
    },
    toContent(item){
      this.item = item
      this.$api.articleInfo({},{id:item.id}).then(res => {
        if(res.data.isPay){
          this.$router.push({name:'Aticle',query:{id:item.id},params:{item:res.data}})
        }else{
          this.$refs.pay.showPay({payType:4,bizId:item.id,payMoney:item.money},this.callback)
        }
      })
    },
    getData() {
      let current = this.current,
        pageSize = this.pageSize;
      this.$api.articleList(Object.assign({pageNo:current,pageSize:pageSize},this.serchObj)).then(res => {
        this.data = res.data.rows
        this.total = res.data.totalRows
        this.totalPage = res.data.totalPage
      })
      this.$api.dropDown(Object.assign({},{code:'article_type'})).then(res => {
        // this.type = res.data.filter(item => {return item.value!='免费'})
        this.type = res.data
        this.type.unshift({id:null,value:'全部类型'})
      })
    },
    flagTab(index) {
      this.flaged = index;
      this.serchObj.click = this.flags[index].id
      this.getData();
    },
    timeTab(index) {
      this.timeed = index;
      let se = this.time[index].id
      if(se){
        let seArr = se.split("-")
        this.serchObj.startTime = seArr[0]
        if(seArr[1]){
          this.serchObj.endTime = seArr[1]
        }
      }else{
        this.serchObj.startTime = null
        this.serchObj.endTime = null
      }
      this.getData();
    },
    typeTab(index) {
      this.typeed = index;
      this.serchObj.type = this.type[index].id
      this.getData();
    },
    start() {
      this.current = 1;
      this.getData();
    },
    prev() {
      if(this.current>1){
        this.current -= 1
        this.getData()
      }
    },
    next() {
      if (this.current < this.totalPage) {
        this.current += 1;
        this.getData();
      }
    },
    end() {
      this.current = this.totalPage;
      this.getData();
    },
    //秒转换为时：分：秒格式
    timeFormat(t) {
      if (!t) return;
      if (t < 60) return "00:" + ((i = t) < 10 ? "0" + i : i);
      if (t < 3600) return "" + ((a = parseInt(t / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
      if (3600 <= t) {
        var a, i, e = parseInt(t / 3600);
        return (e < 10 ? "0" + e : e) + ":" + ((a = parseInt(t % 3600 / 60)) < 10 ? "0" + a : a) + ":" + ((i = t % 60) < 10 ? "0" + i : i);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
// 公共样式
.hide {
  display: none;
}
.show {
  display: block;
}
a {
  color: #656565;
}
* {
  font-family: "微软雅黑";
}
.no-data{
  width: 100%;
  padding-top: 10%;
  font-size: 35px;
  color: #d7d7d7;
  text-align: center;
}
.card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  color: #626262;
  font-size: 13px;
  font-weight: unset;
}
.container {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .main {
    width: 1366px;
    margin: 0 auto;
    height: calc(100% - 122px);
    box-sizing: border-box;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    .serchBox {
      display: flex;
      position: relative;
      .serchIcon {
        height: 30px;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -15px;
      }
      .serchInp {
        height: 42px;
        border: 1px solid #eeeeee;
        flex: 1;
        padding-left: 55px;
        color: rgb(100, 100, 100);
        font-size: 15px;
        border: 0px;
      }

      .serchBtn {
        margin-left: 20px;
        width: 100px;
        color: white;
        border: 1px solid #063A61;
        background-color: #063A61;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .typeBox {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      .tagBox {
        display: flex;
        color: #999999;
        font-size: 16px;
        flex-wrap: wrap;
        div {
          text-align: center;
          cursor: pointer;
          padding: 5px 10px;
          margin: 0px 10px;
        }
        div:hover {
          background-color: #063A61;
          color: white;
        }
        .active {
          background-color: #063A61;
          color: white;
        }
      }
      .tagBox:last-child {
        margin-top: 10px;
      }
    }
    .tipBox {
      font-size: 24px;
      color: #b8aca0;
      width: 100%;
      height: calc(100% - 82px);
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .listbox {
      margin-top: 20px;
      background-color: white;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      column-gap: 60px;
      row-gap: 20px;
      flex-wrap: wrap;
      height: calc(100% - 1rem);
      position: relative;
      overflow: auto;
      img {
        max-height: 120px;
        object-fit: cover;
      }
      .tcount {
        position: absolute;
        top: 100px;
        color: white;
        right: 0px;
        border-top-left-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        padding: 1px 5px;
        font-size: 12px;
      }
      .itemtitle {
        padding: 10px;
        box-sizing: border-box;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;
          font-size: 14px;
        }
        span:hover{
          color:#063A61;
        }
      }
      .czBox {
        display: flex;
        box-sizing: border-box;
        padding: 10px 0 0 0;
        position: absolute;
        bottom: 10px;
        width: 100%;
        div {
          color: #9ca3ab;
          flex: 1;
        }
        .countTxt {
          background-repeat: no-repeat;
          background-image: url("../../static/images/mingtai/countIcon.png");
          padding-left: 20px;
          background-position: 0% 50%;
          font-size: 12px;
          background-size: 16px 16px;
          text-align: left;
        }
        .dateTxt {
          background-repeat: no-repeat;
          background-image: url("../../static/images/mingtai/dateIcon.png");
          padding-left: 20px;
          background-position: 0% 50%;
          font-size: 12px;
          background-size: 16px 16px;
        }
      }
    }
    .footetPage {
      width: 68%;
      text-align: center;
      position: fixed;
      bottom: 40px;
      padding: 15px;
      a {
        font-size: 14px;
        padding: 0px 5px;
        border: 1px solid #cecece;
        margin: 0px 5px;
      }
      span {
        font-size: 14px; 
        color: #656565;
      }
      a:hover {
        color: #063A61;
      }
    }
  }
}
</style>