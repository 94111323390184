<template>
  <div id="app">
    <div class="main-container" v-if="$route.meta.keepAlive">
      <keep-alive>
      </keep-alive>
      <router-view/>
    </div>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    changeVisible() {
      this.visible = !this.visible;
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

.main-container {
  width: 100%;
  height: 100%;
}

.visible-btn {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(-30px, -90%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: rgba(51,51,51,0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
</style>
